/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import _ from 'lodash';
import React, { useState } from 'react';

import { colors } from '@cimpress/react-components';

import { DisplayValue, UiDictionaryValue } from '../../types';
import AddElementButton from '../AddElementButton';
import PlusIconButton from '../styledComponents/PlusIconButton';
import Tip from '../styledComponents/Tip';
import RankedDictionaryRow from './RankedDictionaryRow';

export default function RankedDictionaryTable({
  actionId,
  dictionary,
  updateDictionary,
  focusedInput,
  onFocus,
  allowTextInput,
  fulfillers,
}: {
  actionId: string;
  dictionary: Record<string, UiDictionaryValue>;
  updateDictionary: (dictionary: Record<string, any>) => void;
  focusedInput: string | null;
  onFocus: (input: string) => void;
  allowTextInput: boolean;
  fulfillers?: Record<string, DisplayValue<string>>;
}) {
  const dictionaryRowContainer = css`
    max-height: 300px;
    overflow-y: auto;
    width: 500px;

    border-bottom: ${_.keys(dictionary).length > 4 ? '1px solid ' + colors.slate : ''};
  `;

  const usedKeys = _.keys(dictionary);
  const unusedKeys = allowTextInput
    ? []
    : _.filter(fulfillers, ({ value: fulfillerId }) => !_.includes(usedKeys, fulfillerId));
  const [unableToAddNewKey, setUnableToAddNewKey] = useState(false);

  const handleSelectKey = ({ oldKey, newKey }: { oldKey: string; newKey: string }) => {
    if (oldKey === newKey) {
      return;
    }

    const clonedDictionary = _.cloneDeep(dictionary);
    clonedDictionary[newKey] = clonedDictionary[oldKey];
    delete clonedDictionary[oldKey];

    updateDictionary(clonedDictionary);
  };

  const handleSelectValue = ({ newValue, key }: { newValue: string; key: string }) => {
    const clonedDictionary = _.cloneDeep(dictionary);
    clonedDictionary[key].value = newValue;
    updateDictionary(clonedDictionary);
  };

  const handleDelete = (key: string) => {
    setUnableToAddNewKey(false);
    const clonedDictionary = _.cloneDeep(dictionary);
    delete clonedDictionary[key];
    updateDictionary(clonedDictionary);
  };

  const handleAdd = () => {
    const clonedDictionary = _.cloneDeep(dictionary);
    const index = _.keys(dictionary).length + 1;

    clonedDictionary[''] = { value: 0, index };

    updateDictionary(clonedDictionary);
  };

  const addRowButtonDisabled = !allowTextInput && unableToAddNewKey;
  const shouldShowAddKeyButton = _.isEmpty(dictionary);

  return (
    <div css={tableStyles}>
      {shouldShowAddKeyButton && (
        <div css={centered}>
          <AddElementButton onClick={handleAdd} text="Add a new key" />
        </div>
      )}
      <div css={tipStyles}>
        <Tip>
          You can use the special key &apos;default&apos; to assign a default priority to any keys
          not explicitly listed
        </Tip>
      </div>
      <div css={dictionaryRowContainer}>
        {_(dictionary)
          .entries()
          .sortBy(([, rank]) => rank.index)
          .map(([identifier, rank], index) => {
            const label = allowTextInput
              ? identifier // skuCode
              : fulfillers?.[identifier]?.label || ''; // fulfiller name
            return (
              <RankedDictionaryRow
                key={`dictionary-row-${index}`}
                actionId={actionId}
                selectedKey={{ label, value: identifier }}
                onChangeSelectedKey={({ value: incomingValue }) =>
                  handleSelectKey({ oldKey: identifier, newKey: incomingValue })
                }
                selectedValue={{ label: `${Number(rank.value) + 1}`, value: rank.value }}
                onChangeSelectedValue={({ value: incomingValue }) =>
                  handleSelectValue({
                    newValue: incomingValue,
                    key: identifier,
                  })
                }
                allowedKeys={unusedKeys}
                onDelete={() => handleDelete(identifier)}
                focusedInput={focusedInput}
                onFocus={onFocus}
                allowTextInput={allowTextInput}
              />
            );
          })
          .value()}
      </div>
      {_.keys(dictionary).length > 4 && (
        <Tip css={scrollTipStyles}>Scroll to see more table rows.</Tip>
      )}
      {_.keys(dictionary).length > 0 && (
        <PlusIconButton onClick={handleAdd} disabled={addRowButtonDisabled} />
      )}
      {unableToAddNewKey && !allowTextInput && (
        <p css={centeredAndMargin}>
          There are no more valid keys that can be added. If this is unexpected, please contact
          @platformsupport in the #mcp-platform-support Slack channel to investigate your
          permissions.
        </p>
      )}
    </div>
  );
}

const scrollTipStyles = css`
  margin-top: 10px;
`;

const tableStyles = css`
  margin-left: 16px;
  width: 100%;
`;

const centered = css`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const centeredAndMargin = css`
  ${centered};
  color: ${colors.warning.darkest};
  margin-bottom: 16px;
`;

const tipStyles = css`
  margin-bottom: 10px;
  margin-top: -10px; // The action grid area has a 10px gap
`;
