import _ from 'lodash';
import toLower from 'lodash/toLower';
import React, { useContext, useEffect, useState } from 'react';
import { RawNodeDatum } from 'react-d3-tree';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Alert, Spinner } from '@cimpress/react-components';

import { IfThenContext } from '../context/IfThenContext';
import { setError, setWarning } from '../features/alerts/alertContentSlice';
import { setProductConfigurationUrl } from '../features/productConfigurationSearch/productConfigurationSlice';
import { setItemOrOrderId } from '../features/resourceSearch/resourceSearchSlice';
import {
  setBaseWorkingConfigurationSnapshot,
  setEtag,
  setRoutingConfiguration,
  setSelectedNode,
  setSkuCode,
  setWorkingConfiguration,
} from '../features/selectedConfiguration/selectedConfigurationSlice';
import { setSelectedItem } from '../features/testInput/testInputSlice';
import {
  getRoutingConfigurationByBuyerAndSku,
  getRoutingConfigurationById,
} from '../services/routingConfigurationService';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import {
  DisplayValue,
  RoutingConfigurationV3,
  WorkingRoutingConfigurationNode,
  WorkingRoutingConfigurationV3,
} from '../types';
import { buildTreeAndIdentifyUnreachableNodes } from '../utils/buildTreeAndIdentifyUnreachableNodes';
import { removeDanglingIfThenReferences } from '../utils/conversions';
import { hydrateRoutingConfiguration } from '../utils/hydrate';
import { removeOutgoingNodeReferences } from '../utils/nodeUtils';
import { AppAlert } from './AppAlert';
import PublishButton from './PublishButton';
import styles from './editRoutingConfigurationPage.module.scss';
import NodeSidebar from './nodeSidebar/NodeSidebar';
import NodeTreeContainer from './nodeTree/NodeTreeContainer';
import UnassignedNodeContainer from './unreachableNodes/UnassignedNodeContainer';

export default function EditRoutingConfigurationPage() {
  const navigate = useNavigate();
  const { accountId, id } = useParams();
  const { pathname, search } = useLocation();

  const selectedConfiguration = useAppSelector(
    (state) => state.selectedConfiguration.routingConfiguration,
  );
  const skuCode = useAppSelector((state) => state.selectedConfiguration.skuCode);
  const workingConfiguration = useAppSelector(
    (state) => state.selectedConfiguration.workingConfiguration,
  );
  const baseConfigurationSnapshot = useAppSelector(
    (state) => state.selectedConfiguration.baseWorkingConfigurationSnapshot,
  );
  const selectedNode = useAppSelector((state) => state.selectedConfiguration.selectedNode);

  const dispatch = useAppDispatch();

  const dispatchSelectedConfiguration = (rc: RoutingConfigurationV3) =>
    dispatch(setRoutingConfiguration(rc));
  const dispatchWorkingConfiguration = (rc: WorkingRoutingConfigurationV3 | null) =>
    dispatch(setWorkingConfiguration(rc));
  const dispatchSelectedNode = (node: WorkingRoutingConfigurationNode | null) =>
    dispatch(setSelectedNode(node));
  const dispatchErrors = ({ messages, title }: { messages: string[]; title: string }) =>
    dispatch(setError({ messages, title }));
  const clearSelectedRouteTesterItem = () => {
    dispatch(setProductConfigurationUrl(''));
    dispatch(setSelectedItem());
    dispatch(setItemOrOrderId());
  };
  const dispatchEtag = (etag: string) => dispatch(setEtag(etag));
  const dispatchSkuCode = (sku: string) => dispatch(setSkuCode(sku));

  // Synchronize all components with changes to ifThen names or IDs
  const { setIfThenMap } = useContext(IfThenContext);

  const [showSidebar, setShowSidebar] = useState(false);

  const [unreachableNodes, setUnreachableNodes] = useState<WorkingRoutingConfigurationNode[]>([]);
  const [tree, setTree] = useState<RawNodeDatum>({
    attributes: { id: '' },
    name: '',
  } as RawNodeDatum);

  useEffect(() => {
    const snapshot = hydrateRoutingConfiguration(selectedConfiguration);
    // Prevent these from sharing the same reference
    dispatchWorkingConfiguration(snapshot);
    dispatch(setBaseWorkingConfigurationSnapshot(_.cloneDeep(snapshot)));
  }, [JSON.stringify(selectedConfiguration)]);

  useEffect(() => {
    if (workingConfiguration) {
      const clonedConfiguration = _.cloneDeep(workingConfiguration);
      try {
        const {
          tree: _tree,
          unreachableNodes: _unreachableNodes,
          nodeIdsNotFound,
        } = buildTreeAndIdentifyUnreachableNodes(clonedConfiguration);
        setTree(_tree);

        if (nodeIdsNotFound.length) {
          dispatch(
            setWarning({
              title: 'Expected node IDs not found',
              messages: [
                'Node IDs referenced in the routing configuration could not be found:',
                nodeIdsNotFound.join(', '),
              ],
            }),
          );
        }

        _.forEach(_unreachableNodes, (node) => removeOutgoingNodeReferences(node));
        setUnreachableNodes(_unreachableNodes);

        const updatedIfThenMap = _.reduce(
          clonedConfiguration.nodes,
          (ifThens, node) => {
            _.forEach(node.ifThens, (ifThen) => {
              ifThens[ifThen.id] = {
                label: ifThen.name || ifThen.id,
                value: ifThen.id,
              };
            });
            return ifThens;
          },
          [] as Record<string, DisplayValue<string>>[],
        );
        
        setIfThenMap(updatedIfThenMap);
        removeDanglingIfThenReferences(clonedConfiguration, Object.keys(updatedIfThenMap));
        dispatchWorkingConfiguration(clonedConfiguration);
      } catch (e: any) {
        dispatchErrors({
          title: 'An error occurred while building the Routing Configuration',
          messages: [e.message],
        });
      }
    }
  }, [JSON.stringify(workingConfiguration)]);

  const selectNodeObjectAndOpenSidebar = (node: WorkingRoutingConfigurationNode) => {
    if (!workingConfiguration) {
      return;
    }

    dispatchSelectedNode(node);
    setShowSidebar(true);
  };

  const selectCanvasNodeAndOpenSidebar = (element: HTMLElement) => {
    if (!workingConfiguration) {
      return;
    }
    const node = _.find(
      workingConfiguration.nodes,
      (n) => n.id === element.getAttribute('data-id'),
    )!;

    dispatchSelectedNode(node);
    setShowSidebar(true);
  };

  const selectedTabId = useAppSelector((state) => state.tab.selectedTabIndex);

  const searchConfiguration = async () => {
    clearSelectedRouteTesterItem();
    if (id) {
      dispatchSkuCode('');
      try {
        const { configuration, etag } = await getRoutingConfigurationById(id);
        dispatchSelectedConfiguration(configuration);
        dispatchWorkingConfiguration(hydrateRoutingConfiguration(configuration));
        dispatchEtag(etag);
        if (accountId) {
          navigate(`/accounts/${accountId}/configurations/edit/${configuration.id}`);
        } else {
          navigate(`/configurations/edit/${configuration.id}`);
        }
      } catch (e: any) {
        const { response } = e;
        const errors = [response?.status, response?.errorReason, `ID: ${id}`];
        if (response?.additionalDetails) {
          errors.push(response.additionalDetails);
        }

        dispatchErrors({
          title: 'Retrieval Error',
          messages: errors,
        });

        if (accountId) {
          navigate(`/accounts/${accountId}/configurations`);
        } else {
          navigate('/configurations');
        }
      }
    } else {
      const isCreatingNewConfiguration = pathname.includes('create');

      // This is a lot of checks, but let's break it down...
      if (
        !selectedConfiguration && // If we don't already have a configuration,
        !id && // and we don't have an ID to search by,
        !(skuCode && accountId) && // and we don't have a buyer + SKU to search by,
        !isCreatingNewConfiguration && // and we're not creating a new configuration,
        selectedTabId === 0 // and we're on the builder tab...
      ) {
        // Then we don't have enough information, and need to start from scratch.
        dispatchErrors({
          title: 'Navigation Error',
          messages: ['Not enough information; did you use an incomplete URL?'],
        });

        if (accountId) {
          navigate(`/accounts/${accountId}/configurations`);
        } else {
          navigate('/configurations');
        }
        return <></>;
      }

      let skuCodeForSearch = skuCode;

      if (!skuCode && isCreatingNewConfiguration) {
        const skuCodeFromParams = new URLSearchParams(search).get('skuCode');
        if (skuCodeFromParams) {
          dispatchSkuCode(skuCodeFromParams);
          skuCodeForSearch = skuCodeFromParams;
        }
      }

      const { configuration } = await getRoutingConfigurationByBuyerAndSku({
        buyer: accountId,
        skuCode: skuCodeForSearch,
      });

      if (configuration.id && toLower(configuration.id) !== 'default') {
        // Re-search by ID so we can get a usable etag
        const { configuration: configWithId, etag } = await getRoutingConfigurationById(
          configuration.id,
        );
        dispatchSelectedConfiguration(configWithId);
        dispatchWorkingConfiguration(hydrateRoutingConfiguration(configWithId));
        dispatchEtag(etag);
        if (accountId) {
          navigate(`/accounts/${accountId}/configurations/edit/${configuration.id}`);
        } else {
          navigate(`/configurations/edit/${configuration.id}`);
        }
      } else {
        dispatchSelectedConfiguration(configuration);
        dispatchWorkingConfiguration(hydrateRoutingConfiguration(configuration));
        if (accountId) {
          navigate(`/accounts/${accountId}/configurations/create?skuCode=${skuCode}`);
        } else {
          navigate(`/configurations/create?skuCode=${skuCode}`);
        }
      }
    }
  };

  // This will run if a user goes directly to a URL,
  // i.e. they have an ID (or possibly skuCode)
  if (!selectedConfiguration) {
    searchConfiguration();
  }

  const onSaveNode = (nodeToUpdate: WorkingRoutingConfigurationNode, newStartingNode: boolean) => {
    if (!workingConfiguration) {
      return;
    }
    const updatedNodes = _.map(workingConfiguration.nodes, (node) => {
      return node.id === nodeToUpdate.id ? nodeToUpdate : node;
    });

    dispatchWorkingConfiguration({
      ...workingConfiguration,
      nodes: updatedNodes,
      startingNodeId: newStartingNode ? nodeToUpdate.id : workingConfiguration.startingNodeId,
    });
    dispatchSelectedNode(null);
    setShowSidebar(false);
  };

  const handleDiscardChanges = () => {
    setShowSidebar(false);
    dispatchSelectedNode(null);
  };

  const publishButton = (
    <PublishButton
      baseConfiguration={selectedConfiguration!}
      setBaseConfiguration={dispatchSelectedConfiguration}
      baseConfigurationShapshot={baseConfigurationSnapshot!}
      workingConfiguration={workingConfiguration!}
      referenceId={skuCode!}
    />
  );

  const updateWorkingConfigurationAfterNodeDelete = (
    updatedConfig: WorkingRoutingConfigurationV3,
  ) => {
    dispatchWorkingConfiguration(updatedConfig);
    dispatchSelectedNode(null);
    setShowSidebar(false);
  };

  return (
    <div>
      <AppAlert />
      {!accountId && (
        <Alert
          title={'Routing configuration is read-only'}
          className={styles.alert}
          message={'Please log in to edit this routing configuration'}
          status="warning"
          dismissible
        />
      )}
      {selectedConfiguration ? (
        <div className={styles.container}>
          <NodeSidebar
            show={showSidebar}
            onCancel={handleDiscardChanges}
            onSave={onSaveNode}
            selectedNode={selectedNode!}
            nodeOptions={workingConfiguration?.nodes ?? []}
            workingConfiguration={workingConfiguration!}
            onSaveConfiguration={updateWorkingConfigurationAfterNodeDelete}
            nodeIsReachable={!_.includes(unreachableNodes, selectedNode)}
            isStartingNode={selectedNode?.id === workingConfiguration?.startingNodeId}
          />
          <div className={styles.sidebar}>
            <NodeTreeContainer
              onNodeClick={selectCanvasNodeAndOpenSidebar}
              workingConfiguration={workingConfiguration!}
              setWorkingConfiguration={dispatchWorkingConfiguration}
              publishButton={publishButton}
              tree={tree}
            />
            <UnassignedNodeContainer
              nodes={unreachableNodes}
              onClick={selectNodeObjectAndOpenSidebar}
            />
          </div>
        </div>
      ) : (
        <Spinner fullPage={true} />
      )}
    </div>
  );
}
