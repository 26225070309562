import { Draft, PayloadAction, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { Moment } from 'moment';

import { OrderItem } from '../../components/configurationTester/inputs/OrderItems';
import { Item, ItemWithOrderMetadata } from '../../services/items';
import { DisplayValue } from '../../types';
import { searchExistingResources } from '../resourceSearch/resourceSearchSlice';

export interface TestInputState {
  selectedItem?: ItemWithOrderMetadata;
  quantity: number;
  country: string | null;
  address: string;
  city: string;
  state: string;
  postalCode: string;
  capabilities: string[]; // I'm not positive about this
  orderDate: string | Moment;
  promisedArrivalDate: string | Moment;
  items: Item[];
  orderItems: OrderItem[];
  createdDate?: string;
  orderItemsAreValid: boolean;
  isPoBox: boolean;
  usingPickupPoint: boolean;
  fulfillmentCaps: DisplayValue<string>[];
  testInputIsValid: boolean;
}

const initialState: TestInputState = {
  quantity: 0,
  country: null,
  address: '',
  city: '',
  state: '',
  postalCode: '',
  capabilities: [],
  orderDate: '',
  promisedArrivalDate: '',
  items: [],
  orderItems: [],
  orderItemsAreValid: false,
  isPoBox: false,
  usingPickupPoint: false,
  fulfillmentCaps: [],
  testInputIsValid: false,
};

const validateTestInput = (state: Draft<TestInputState>) => {
  const requiredFields = [
    'country',
    'postalCode',
    'orderDate',
    'promisedArrivalDate',
    'items',
    'orderItemsAreValid',
  ];

  return _.every(requiredFields, (field) => !!state[field]);
};

const testInputSlice = createSlice({
  name: 'testInput',
  initialState,
  reducers: {
    setSelectedItem(state, action: PayloadAction<ItemWithOrderMetadata | undefined>) {
      const selectedItem = action.payload;
      state.selectedItem = selectedItem;

      state.orderDate = _.get(selectedItem, 'statuses.platformAccepted.updatedDate', '');
      state.promisedArrivalDate = selectedItem?.localPromisedArrivalDate || '';

      const destinationAddress = selectedItem?.deliveryRequest?.destinationAddress;
      state.country = destinationAddress?.country || null;
      state.postalCode = destinationAddress?.postalCode || '';
      state.isPoBox = destinationAddress?.isPOBox || false;

      if (selectedItem?.fulfillmentCapabilities) {
        state.fulfillmentCaps = selectedItem.fulfillmentCapabilities.map((cap) => ({
          label: cap,
          value: cap,
        }));
      }
    },
    setDestinationFromItem(state, action: PayloadAction<ItemWithOrderMetadata>) {
      if (action.payload?.destinationAddress) {
        state.country = action.payload.destinationAddress.country;
        state.postalCode = action.payload.destinationAddress.postalCode;
        state.isPoBox = action.payload.destinationAddress.isPOBox;
      }
    },
    setOrderItemsAreValid(state, action: PayloadAction<boolean>) {
      state.orderItemsAreValid = action.payload;
    },
    setOrderItems(state, action: PayloadAction<OrderItem[]>) {
      state.orderItems = action.payload;
    },
    setOrderItemsFromRawItems(state, action: PayloadAction<ItemWithOrderMetadata[]>) {
      state.orderItems = _.map(action.payload, (item) => ({
        orderedSkuCode: item.orderedSkuCode || item.mcpSku,
        skuCode: item.mcpSku,
        fulfillerId: item.globalFulfillerId,
      }));
    },
    setUsingPickupPoint(state, action: PayloadAction<boolean>) {
      state.usingPickupPoint = action.payload;
    },
    setFulfillmentCaps(state, action: PayloadAction<DisplayValue<string>[]>) {
      state.fulfillmentCaps = action.payload;
    },
    setOrderDate(state, action: PayloadAction<string | Moment>) {
      state.orderDate = action.payload;
    },
    setPromisedArrivalDate(state, action: PayloadAction<string | Moment>) {
      state.promisedArrivalDate = action.payload;
    },
    setFulfillmentCapsFromSelectedItem(state, action: PayloadAction<ItemWithOrderMetadata>) {
      state.fulfillmentCaps = action.payload.fulfillmentCapabilities.map((cap) => ({
        label: cap,
        value: cap,
      }));
    },
    setIsPoBox(state, action: PayloadAction<boolean>) {
      state.isPoBox = action.payload;
    },
    setCountry(state, action: PayloadAction<string | null>) {
      state.country = action.payload;
    },
    setPostalCode(state, action: PayloadAction<string>) {
      state.postalCode = action.payload;
    },
    postReducerValidateTestInput(state) {
      state.testInputIsValid = validateTestInput(state);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(searchExistingResources.pending, (state) => {
      state.selectedItem = undefined;
    });
    builder.addCase(searchExistingResources.fulfilled, (state, action) => {
      state.selectedItem = undefined;
      state.items = action.payload.items;
      state.orderItemsAreValid = true;
      state.orderItems = _.map(action.payload.items, (item) => ({
        orderedSkuCode: item.orderedSkuCode || item.mcpSku,
        skuCode: item.mcpSku,
        fulfillerId: item.globalFulfillerId,
      }));
    });
  },
});

// Run the validateTestInput action after every action
const multiReducer = (state, action) => {
  const intermediateState = testInputSlice.reducer(state, action);

  if (action.type !== testInputSlice.actions.postReducerValidateTestInput.type) {
    return testInputSlice.reducer(
      intermediateState,
      testInputSlice.actions.postReducerValidateTestInput(),
    );
  }

  // If the action was validateTestInput, just return the state
  return intermediateState;
};

export const {
  setSelectedItem,
  setDestinationFromItem,
  setOrderItemsAreValid,
  setOrderItems,
  setUsingPickupPoint,
  setFulfillmentCaps,
  setOrderItemsFromRawItems,
  setOrderDate,
  setPromisedArrivalDate,
  setIsPoBox,
  setCountry,
  setPostalCode,
  setFulfillmentCapsFromSelectedItem,
} = testInputSlice.actions;

export default multiReducer;
