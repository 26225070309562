import _ from 'lodash';
import moment from 'moment';

import { OrderItem } from '../components/configurationTester/inputs/OrderItems';
import { TestInputState } from '../features/testInput/testInputSlice';
import { ItemFulfillmentCapability, ItemWithOrderMetadata } from '../services/items';
import { RouteV0RequestBody, TestItem } from '../services/testRoutingDecision';
import { RoutingConfigurationV3, WorkingRoutingConfigurationV3 } from '../types';
import { convertWorkingConfigToDbConfig } from './conversions';

export function assembleRoutingEvalArgs({
  inputState,
  accountId,
  selectedItem,
  fulfillmentOptionsUrl,
  routingRequestUrl,
  workingConfiguration,
  baseConfiguration,
  orderItems,
}: {
  inputState: TestInputState;
  accountId: string;
  selectedItem: ItemWithOrderMetadata;
  fulfillmentOptionsUrl: string;
  routingRequestUrl: string;
  workingConfiguration: WorkingRoutingConfigurationV3;
  baseConfiguration: RoutingConfigurationV3;
  orderItems: OrderItem[];
}) {
  const localPromisedArrivalDate = moment.isMoment(inputState.promisedArrivalDate)
    ? (inputState.promisedArrivalDate = inputState.promisedArrivalDate.format('YYYY-MM-DD'))
    : inputState.promisedArrivalDate;

  const isResidential = selectedItem.deliveryRequest.destinationAddress.isResidential;

  const item: TestItem = {
    merchantId: selectedItem.merchantId,
    itemId: selectedItem.itemId,
    skuCode: selectedItem.mcpSku,
    orderedSkuCode: selectedItem.mcpSku,
    quantity: inputState.quantity,
    localPromisedArrivalDate,
    deliveryRequest: {
      destinationAddress: {
        city: inputState.city,
        street1: selectedItem.deliveryRequest.destinationAddress.street1,
        country: inputState.country || selectedItem.deliveryRequest.destinationAddress.country,
        postalCode:
          inputState.postalCode || selectedItem.deliveryRequest.destinationAddress.postalCode,
        isPOBox: inputState.isPoBox,
        isResidential,
      },
    },
    deliveryConfigurations: [
      {
        ...selectedItem.deliveryConfigurations[0],
        quantity: inputState.quantity,
        destinationAddress: {
          country: inputState.country || selectedItem.deliveryRequest.destinationAddress.country,
          postalCode:
            inputState.postalCode || selectedItem.deliveryRequest.destinationAddress.postalCode,
          isPOBox: inputState.isPoBox,
          isResidential: isResidential || false,
        },
      },
    ],
  };

  if (inputState.fulfillmentCaps.length) {
    item.fulfillmentCapabilities = _.map(
      inputState.fulfillmentCaps,
      (fc) => fc.value,
    ) as ItemFulfillmentCapability[];
  }

  const normalizedConfiguration = convertWorkingConfigToDbConfig({
    workingConfiguration,
    baseConfiguration,
  });

  const requestBody: RouteV0RequestBody = {
    routingConfiguration: normalizedConfiguration,
    item,
    metadata: {
      // These will be validated before being used, so we know that orderedSkuCode can't be undefined.
      orderItems: orderItems as ({ orderedSkuCode: string } & OrderItem)[],
    },
    test: true,
    _links: {
      buyerAccount: {
        href: `https://accounts.cimpress.io/api/v1/accounts/${accountId}`,
        name: accountId,
      },
      fulfillmentOptions: {
        href: fulfillmentOptionsUrl,
        name: 'Fulfillment Options',
      },
      routingRequest: {
        href: routingRequestUrl,
        name: 'Routing Request',
      },
    },
  };

  return requestBody;
}
