import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Spinner, Tab, TabCard } from '@cimpress/react-components/';

import { setError } from '../features/alerts/alertContentSlice';
import {
  setEtag,
  setLoading,
  setRoutingConfiguration,
  setSkuCode,
  setWorkingConfiguration,
} from '../features/selectedConfiguration/selectedConfigurationSlice';
import { selectTabByIndex, selectTesterTab } from '../features/tabs/tabSlice';
import { getRoutingConfigurationById } from '../services/routingConfigurationService';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { hydrateRoutingConfiguration } from '../utils/hydrate';
import EditRoutingConfigurationPage from './EditRoutingConfigurationPage';
import ConfigurationTesterContainer from './configurationTester/ConfigurationTesterContainer';
import styles from './tabContainer.module.scss';

export default function TabContainer() {
  const useTestTab = process.env.REACT_APP_USE_TEST_TAB === 'true';

  const navigate = useNavigate();
  const { id, accountId } = useParams();
  const { pathname } = useLocation();
  const isCreatingNewConfiguration = pathname.includes('create');

  // These are in Redux state so that we can navigate the user
  // directly to one tab or another depending on where they're coming from
  const selectedTabIndex = useAppSelector((state) => state.tab.selectedTabIndex);

  const dispatch = useAppDispatch();
  const setSelectedIndex = (index: number) => dispatch(selectTabByIndex(index));
  const selectedRoutingConfiguration = useAppSelector(
    (state) => state.selectedConfiguration.routingConfiguration,
  );
  const isLoadingSelectedConfiguration = useAppSelector(
    (state) => state.selectedConfiguration.loading,
  );
  const selectedSkuCode = useAppSelector((state) => state.selectedConfiguration.skuCode);
  const dispatchErrors = ({ messages, title }: { messages: string[]; title: string }) =>
    dispatch(setError({ messages, title }));

  const tabs: Tab[] = [
    {
      name: 'Route Builder',
      block: <EditRoutingConfigurationPage />,
    },
    {
      name: 'Route Tester',
      block: <ConfigurationTesterContainer />,
    },
  ];

  const navigateOnTabSelect = (event, index: number) => {
    setSelectedIndex(index);
    if (index === 0) {
      if (selectedRoutingConfiguration?.id && selectedRoutingConfiguration.id !== 'default') {
        navigate(`/accounts/${accountId}/configurations/edit/${selectedRoutingConfiguration.id}`);
      } else if (selectedSkuCode) {
        navigate(`/accounts/${accountId}/configurations/create?skuCode=${selectedSkuCode}`);
      } else {
        dispatchErrors({
          title: 'Navigation Error',
          messages: ['Not enough information; did you use an incomplete URL?'],
        });
        // Not enough information; navigate back to search page
        navigate(`/accounts/${accountId}/configurations`);
      }
    } else {
      navigate(`/accounts/${accountId}/configurations/evaluate`);
    }
  };

  let renderOutput;

  const searchConfigurationById = async () => {
    if (!id) {
      return;
    }

    dispatch(setLoading(true));
    dispatch(setSkuCode(''));

    try {
      const { configuration, etag } = await getRoutingConfigurationById(id);
      dispatch(setRoutingConfiguration(configuration));
      dispatch(setWorkingConfiguration(hydrateRoutingConfiguration(configuration)));
      dispatch(setEtag(etag));
      dispatch(setLoading(false));
    } catch (e: any) {
      dispatch(setLoading(false));
      const { response } = e;
      const errors = [response?.status, response?.errorReason, `ID: ${id}`];
      if (response?.additionalDetails) {
        errors.push(response.additionalDetails);
      }

      dispatchErrors({
        title: 'Retrieval Error',
        messages: errors,
      });

      navigate(`/accounts/${accountId}/configurations`);
    }
  };

  // If the test tab isn't enabled, we only care if we're loading
  // a routing configuration or not.
  if (isLoadingSelectedConfiguration) {
    renderOutput = <Spinner fullPage />;
  } else if (!useTestTab) {
    renderOutput = <EditRoutingConfigurationPage />;

    // Otherwise, we need to check what state we're in.

    // For routes ending with /create?skuCode=abc, including
    // coming directly via URL
  } else if (isCreatingNewConfiguration) {
    // The default configuration will be fetched when the Edit page
    // is loaded.
    renderOutput = (
      <TabCard
        tabs={tabs}
        className={styles.container}
        selectedIndex={selectedTabIndex}
        onSelect={navigateOnTabSelect}
      />
    );

    // Navigation directly to the testing tab
  } else if (!id && !selectedRoutingConfiguration) {
    renderOutput = <ConfigurationTesterContainer />;
    dispatch(selectTesterTab());
  } else {
    // Tabbing over when we already have a routing configuration
    if (selectedRoutingConfiguration) {
      renderOutput = (
        <TabCard
          tabs={tabs}
          className={styles.container}
          selectedIndex={selectedTabIndex}
          onSelect={navigateOnTabSelect}
        />
      );
      // Coming directly from a URL
    } else {
      searchConfigurationById();
    }
  }

  return <div>{renderOutput}</div>;
}
