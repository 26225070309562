import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

import { runRoutingEvaluation } from '../evaluationResults/evaluationResultsSlice';
import { searchExistingResources } from '../resourceSearch/resourceSearchSlice';

interface AlertContent {
  title: string;
  messages: string[];
  status?: 'danger' | 'warning' | 'info' | 'success';
}

interface TitleAndMessages {
  title: string;
  messages: string | string[];
}

interface TitleAndOptionalMessages {
  title: string;
  messages?: string | string[];
}

const initialState: AlertContent = {
  messages: [],
  title: '',
};

const normalizeToArray = (messages: string | string[]) => {
  return _.isArray(messages) ? messages : [messages];
};

const AlertContentSlice = createSlice({
  name: 'getTimezone',
  initialState,
  reducers: {
    setError(state, action: PayloadAction<TitleAndMessages>) {
      const { messages, title } = action.payload;
      state.messages = normalizeToArray(messages);
      state.title = title;
      state.status = 'danger';
    },
    setWarning(state, action: PayloadAction<TitleAndMessages>) {
      const { messages, title } = action.payload;
      state.messages = normalizeToArray(messages);
      state.title = title;
      state.status = 'warning';
    },
    setSuccess(state, action: PayloadAction<TitleAndOptionalMessages>) {
      const { messages, title } = action.payload;
      state.messages = messages ? normalizeToArray(messages) : [];
      state.title = title;
      state.status = 'success';
    },
    clearAlert(state) {
      state.messages = [];
      state.title = '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(searchExistingResources.rejected, (state, action) => {
      state.messages = [(action.payload as any) ?? 'An unexpected error occurred'];
      state.title = 'An error occurred while searching for item or order';
    });
    builder.addCase(runRoutingEvaluation.fulfilled, (state, action) => {
      if (action?.payload?._embedded?.decision) {
        state.messages = ['Routing evaluation completed successfully'];
        state.title = 'Evaluation Results';
        state.status = 'success';
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      }
    });
  },
});

export const { setError, setWarning, clearAlert, setSuccess } = AlertContentSlice.actions;
export default AlertContentSlice.reducer;
