import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getRoutingConfigurationByBuyerAndSku } from '../../services/routingConfigurationService';
import {
  RoutingConfigurationV3,
  WorkingRoutingConfigurationNode,
  WorkingRoutingConfigurationV3,
} from '../../types';
import { hydrateRoutingConfiguration } from '../../utils/hydrate';

interface selectedConfigurationState {
  skuCode: string;
  routingConfiguration: RoutingConfigurationV3 | null;
  workingConfiguration: WorkingRoutingConfigurationV3 | null;
  baseWorkingConfigurationSnapshot: WorkingRoutingConfigurationV3 | null;
  selectedNode: WorkingRoutingConfigurationNode | null;
  workingNode: WorkingRoutingConfigurationNode | null;
  etag: string;
  loading: boolean;
}

const initialState: selectedConfigurationState = {
  skuCode: '',
  routingConfiguration: null,
  workingConfiguration: null,
  baseWorkingConfigurationSnapshot: null,
  selectedNode: null,
  workingNode: null,
  etag: '',
  loading: false,
};

type SearchAndSetParams = {
  buyer: string;
  skuCode: string;
};

export const searchAndSetRoutingConfiguration = createAsyncThunk(
  'selectedConfiguration/searchAndSetConfig',
  async ({ buyer, skuCode }: SearchAndSetParams) => {
    const { configuration, etag } = await getRoutingConfigurationByBuyerAndSku({ buyer, skuCode });
    return { configuration, etag };
  },
);

const selectedConfigurationSlice = createSlice({
  name: 'selectedConfiguration',
  initialState,
  reducers: {
    setSkuCode(state, action: PayloadAction<string>) {
      state.skuCode = action.payload;
    },
    setRoutingConfiguration(state, action: PayloadAction<RoutingConfigurationV3>) {
      state.routingConfiguration = action.payload;
      state.loading = false;
    },
    resetRoutingConfiguration(state) {
      state.routingConfiguration = null;
    },
    setEtag(state, action: PayloadAction<string>) {
      state.etag = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setWorkingConfiguration(state, action: PayloadAction<WorkingRoutingConfigurationV3 | null>) {
      state.workingConfiguration = action.payload;
    },
    setBaseWorkingConfigurationSnapshot(
      state,
      action: PayloadAction<WorkingRoutingConfigurationV3 | null>,
    ) {
      state.baseWorkingConfigurationSnapshot = action.payload;
    },
    setSelectedNode(state, action: PayloadAction<WorkingRoutingConfigurationNode | null>) {
      state.selectedNode = action.payload;
    },
    setWorkingNode(state, action: PayloadAction<WorkingRoutingConfigurationNode | null>) {
      state.workingNode = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(searchAndSetRoutingConfiguration.fulfilled, (state, action) => {
      const hydratedConfiguration = hydrateRoutingConfiguration({
        ...action.payload.configuration,
      });
      state.routingConfiguration = action.payload.configuration;
      // Ensure that these have different object references
      state.workingConfiguration = { ...hydratedConfiguration! };
      state.baseWorkingConfigurationSnapshot = { ...hydratedConfiguration! };
      state.etag = action.payload.etag;
    });
  },
});

export const {
  setSkuCode,
  setRoutingConfiguration,
  resetRoutingConfiguration,
  setEtag,
  setLoading,
  setWorkingConfiguration,
  setBaseWorkingConfigurationSnapshot,
  setSelectedNode,
  setWorkingNode,
} = selectedConfigurationSlice.actions;
export default selectedConfigurationSlice.reducer;
