import React from 'react';

import IconCopy from '@cimpress-technology/react-streamline-icons/lib/IconCopy';
import { Checkbox, Copy, DatePicker } from '@cimpress/react-components';

import {
  setCountry,
  setFulfillmentCaps,
  setIsPoBox,
  setOrderDate,
  setOrderItems,
  setOrderItemsAreValid,
  setPostalCode,
  setPromisedArrivalDate,
} from '../../features/testInput/testInputSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import StyledTextField from '../styledComponents/StyledTextField';
import Tip from '../styledComponents/Tip';
import GenericSelectorWrapper from './GenericSelectorWrapper';
import CountrySelector from './inputs/CountrySelector';
import FulfillmentCapabilitiesSelector from './inputs/FulfillmentCapabilitiesSelector';
import OrderItems from './inputs/OrderItems';
import styles from './testerFormFields.module.scss';

export default function TesterFormFields() {
  const dispatch = useAppDispatch();

  const skuCode = useAppSelector((state) => state.selectedConfiguration.skuCode);
  const orderItems = useAppSelector((state) => state.testInput.orderItems);
  const orderItemsAreValid = useAppSelector((state) => state.testInput.orderItemsAreValid);

  const productConfigurationUrl = useAppSelector(
    (state) => state.productConfiguration.productConfigurationUrl,
  );

  const orderDate = useAppSelector((state) => state.testInput.orderDate);
  const promisedArrivalDate = useAppSelector((state) => state.testInput.promisedArrivalDate);
  const usingPickupPoint = useAppSelector((state) => state.testInput.usingPickupPoint);
  const fulfillmentCaps = useAppSelector((state) => state.testInput.fulfillmentCaps);

  const country = useAppSelector((state) => state.testInput.country);
  const postalCode = useAppSelector((state) => state.testInput.postalCode);
  const isPoBox = useAppSelector((state) => state.testInput.isPoBox);

  const dispatchOrderDate = (date) => {
    dispatch(setOrderDate(date));
  };

  const dispatchPromisedArrivalDate = (date) => {
    dispatch(setPromisedArrivalDate(date));
  };

  const dispatchCountry = (_country: string | null) => {
    dispatch(setCountry(_country));
  };

  const dispatchPostalCode = (_postalCode: string) => {
    dispatch(setPostalCode(_postalCode));
  };

  return (
    <div>
      <h3 className={styles.semiBold}>Order Criteria</h3>
      <h4 className={styles.semiBold}>Product Information</h4>
      <StyledTextField
        value={skuCode}
        label="SKU"
        className={styles.skuCodeInput}
        onChange={() => {}}
        disabled
      />
      <GenericSelectorWrapper productId={skuCode} />

      {productConfigurationUrl && (
        <div>
          <h4 className={styles.semiBold}>Product Configuration URL</h4>
          <div className={styles.copyContainer}>
            <div className={styles.productConfigUrl}>
              <a target="_blank" href={productConfigurationUrl} rel="noreferrer">
                {productConfigurationUrl}
              </a>
            </div>
            <Copy className={styles.copyButton} variant="button" value={productConfigurationUrl}>
              <IconCopy size="lg" weight="light" />
            </Copy>
          </div>
        </div>
      )}

      <div>
        <h4 className={styles.semiBold}>Production Information</h4>
        {(!orderDate || !promisedArrivalDate) && (
          <p className={styles.errorText}>Please enter dates</p>
        )}
        <div>
          <DatePicker
            label="Order date"
            value={orderDate}
            onChange={dispatchOrderDate}
            required
            isClearable
          />
        </div>
        <div>
          <DatePicker
            label="Promised arrival date"
            value={promisedArrivalDate}
            onChange={dispatchPromisedArrivalDate}
            required
            isClearable
          />
        </div>

        <h4 className={styles.semiBold}>Shipping Information</h4>
        {country && postalCode && (
          <p className={styles.destinationWarning}>
            Please note that if you change the delivery destination, the tool cannot verify that the
            fulfillment options can be delivered to the new destination.
          </p>
        )}
        {(!country || !postalCode) && (
          <p className={styles.errorText}>Please enter destination information</p>
        )}
        <CountrySelector value={country} onChange={dispatchCountry} isDisabled={usingPickupPoint} />
        <StyledTextField
          value={postalCode}
          label="Postal Code"
          onChange={(e) => dispatchPostalCode(e.target.value)}
          disabled={usingPickupPoint}
          required
        />

        <Checkbox
          checked={isPoBox}
          label="PO Box"
          onChange={(e, payload) => dispatch(setIsPoBox(payload))}
          disabled={usingPickupPoint}
          className={styles.poBox}
        />
      </div>

      {/* TODO - this will eventually be enabled... but not in the MVP. */}

      {/* <div>
        <DottedLine />
        <h4 className={`${styles.header} ${styles.semiBold}`}> Or </h4>
        <DottedLine />
      </div>
      <Checkbox
        checked={usingPickupPoint}
        label="Is pickup point"
        onChange={(e) => dispatch(setUsingPickupPoint(e.target.checked))}
        className={styles.poBox}
      /> */}

      <h4 className={styles.semiBold}>Capabilities</h4>
      <FulfillmentCapabilitiesSelector
        value={fulfillmentCaps}
        onChange={(caps) => dispatch(setFulfillmentCaps(caps))}
      />

      <h3 className={styles.semiBold}>Order Metadata</h3>
      <Tip>
        <p>These represent other items on the order.</p>
        <p>
          To represent an item that was ordered but has not been routed, enter an OrderedSku but no
          Routed SKU or Fulfiller ID.
        </p>
        <p>
          To represent an item that has already been routed, enter an OrderedSku and one (or both)
          of Routed SKU and Fulfiller ID.
        </p>
        <p>
          If you load an existing item from an order, these will be populated automatically with the
          items found on that order.
        </p>
      </Tip>
      <OrderItems
        orderItems={orderItems}
        setOrderItems={(items) => dispatch(setOrderItems(items))}
        isValid={orderItemsAreValid}
        setIsValid={setOrderItemsAreValid}
      />
    </div>
  );
}
