import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import bPromise from 'bluebird';
import _ from 'lodash';

import { getRoutingDecision } from '../../services/decisions';
import {
  DeliveryRequest,
  ItemWithOrderMetadata,
  ItemWithPromisedDate,
  getItem,
} from '../../services/items';
import { getOrderRequestItems } from '../../services/orderRequest';
import { OrderResponse, getOrder } from '../../services/orders';
import { RootState } from '../../store/store';
import fetchWithAuth from '../../utils/fetchWithAuth';
import { Problem } from '../../utils/isValidPath';
import { ServiceHttpResponseError } from '../../utils/serviceHelpers';

export const getDecisionFromItem = createAsyncThunk(
  'resources/getFulfillmentOptionsUrlFromItem',
  async (item: ItemWithOrderMetadata) => {
    return await getRoutingDecision(item._links.routingDecision.href);
  },
);

export const searchExistingResources = createAsyncThunk(
  'resources/fetchById',
  async ({ resourceId, timezone }: ResourceSearchParams, { getState, rejectWithValue }) => {
    const state = getState() as RootState;

    let order: OrderResponse;
    try {
      // Check to see if it's an order ID first
      order = await getOrder(resourceId, state.resourceSearch.environment);
    } catch {
      // If not, it's an item ID (or invalid, which we just throw)
      try {
        const item = await getItem(resourceId, timezone, state.resourceSearch.environment);
        order = await getOrder(item.orderId, state.resourceSearch.environment);
      } catch (err) {
        if (err instanceof ServiceHttpResponseError && err.response.status === 404) {
          return rejectWithValue(
            'Provided ID could not be found by item or order service. Is the environment set correctly?',
          );
        }
        return rejectWithValue(err);
      }
    }

    const orderRequestItems = await getOrderRequestItems(
      order!.orderId,
      state.resourceSearch.environment,
    );

    const items: ItemWithPromisedDate[] = await bPromise.map(
      orderRequestItems,
      async (ori) => await getItem(ori.itemId, timezone, state.resourceSearch.environment),
    );

    const itemsWithDeliveryRequestDetails = await bPromise.map(items, async (item) => {
      const orderedItem = _.find(orderRequestItems, (ori) => ori.itemId === item.itemId);
      const deliveryRequest = await fetchWithAuth<DeliveryRequest>({
        endpointUrl: item.deliveryConfigurations[0].deliveryRequestLink.href,
      });
      return {
        ...item,
        deliveryRequest,
        productConfigurationUrl:
          orderedItem?._links.productConfiguration.href || item.productConfigurationUrl,
        mcpSku: orderedItem?.skuCode || item.mcpSku,
      };
    });

    return {
      items: itemsWithDeliveryRequestDetails as ItemWithOrderMetadata[],
      order,
    };
  },
);

export type Environment = 'prd' | 'int';

// Should match what's in ORCA
export type TestFulfillmentOptionV1 = {
  optionId: string;
  isValid: boolean;
  problem?: Problem;
  mcpSku?: string;
  isOutOfStock?: boolean;
  priceIncludingCostOfDelay?: string | number;
  priceIncludingAssumedInHouseCost?: string | number;
  fulfillerId: string;
  capacityAvailability?: {
    isCapacityConfigured: boolean;
    hasAvailableCapacity: boolean;
    metMinCapacity: boolean;
    errorCalculatingCapacity: boolean;
  };
  _embedded: {
    deliveryFeasibility?: {
      lateArrival?: boolean;
    };
  };
};

interface ResourceSearchParams {
  resourceId: string;
  timezone: string | undefined;
}

interface ResourceSearchState {
  itemOrOrderId?: string;
  items?: ItemWithOrderMetadata[];
  order?: OrderResponse;
  promisedArrivalDate?: string;
  isLoading: boolean;
  environment: Environment;
  fulfillmentOptions?: TestFulfillmentOptionV1[];
  fulfillmentOptionsUrl?: string;
  routingRequestUrl?: string;
}

const initialState: ResourceSearchState = {
  isLoading: false,
  environment: 'int',
};

const ResourceSearchSlice = createSlice({
  name: 'resourceSearch',
  initialState,
  reducers: {
    setItemOrOrderId(state, action: PayloadAction<string | undefined>) {
      state.itemOrOrderId = action.payload;
    },
    setPromisedArrivalDate(state, action: PayloadAction<string>) {
      state.promisedArrivalDate = action.payload;
    },
    setEnvironment(state, action: PayloadAction<'int' | 'prd'>) {
      state.environment = action.payload;
    },
    resetOrderAndItems(state) {
      state.items = undefined;
      state.order = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchExistingResources.pending.type, (state) => {
        state.isLoading = true;
      })
      .addCase(searchExistingResources.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(searchExistingResources.fulfilled, (state, action) => {
        state.isLoading = false;
        state.items = action.payload.items;
        state.order = action.payload.order;
      })
      .addCase(getDecisionFromItem.fulfilled, (state, action) => {
        state.fulfillmentOptionsUrl = action.payload._links.fulfillmentOptions.href;
        state.routingRequestUrl = action.payload._links.routingRequest.href;
      });
  },
});

export const { setPromisedArrivalDate, setEnvironment, resetOrderAndItems, setItemOrOrderId } =
  ResourceSearchSlice.actions;
export default ResourceSearchSlice.reducer;
